<template>
  <div class="page">
    <!-- 第一模块 -->
    <div class="one">
      <div style="width: 1200px; margin: 0 auto; position: relative;">
        <img src="../assets/home/guanyuwomen.png" />
        <div class="content">
          <div class="content_top">
            <div class="ct_one">西安盛行网络科技有限公司</div>
            <div style="width: 240px; height: 1px; background: #333333"></div>
          </div>
          <div class="content_mid">
            西安盛行网络科技有限公司是“长安大学科技园大宗商品互联网产业应用实验室”的主办单位，承担国家级大宗商品行业的数据调研工作，先后为国家商务部提供西北地区大宗商品行业报告、为陕西省政府提供陕西省能源行业互联网应用调研报告，为中国人民银行第三方支付企业设计金融结算、清算、支付系统，公司以权益平台和技术赋能为依托，为通信、出行、金融等行业提供优质的OT解决方案......
          </div>
        </div>
        <img src="../assets/home/chakangengduo.png" class="more" />
      </div>
    </div>
    <!-- 第二模块 -->
    <div class="two">
      <img src="../assets/home/jiejuefangan.png" />
      <div class="two_content">
        <div class="two_item">
          <div class="hover1">
            <div class="item_logo1"></div>
          </div>
          <div class="item_title">
            {{ resolveList[0].title }}
          </div>
          <div class="item_extend">
            {{ resolveList[0].extends }}
          </div>
        </div>
        <div class="two_item">
          <div class="hover2">
            <div class="item_logo2"></div>
          </div>
          <div class="item_title">
            {{ resolveList[1].title }}
          </div>
          <div class="item_extend">{{ resolveList[1].extends }}</div>
        </div>
        <div class="two_item">
          <div class="hover3">
            <div class="item_logo3"></div>
          </div>
          <div class="item_title">
            {{ resolveList[2].title }}
          </div>
          <div class="item_extend">{{ resolveList[2].extends }}</div>
        </div>
        <div class="two_item">
          <div class="hover4">
            <div class="item_logo4"></div>
          </div>
          <div class="item_title">
            {{ resolveList[3].title }}
          </div>
          <div class="item_extend">{{ resolveList[3].extends }}</div>
        </div>
        <div class="two_item">
          <div class="hover5">
            <div class="item_logo5"></div>
          </div>
          <div class="item_title">
            {{ resolveList[4].title }}
          </div>
          <div class="item_extend">{{ resolveList[4].extends }}</div>
        </div>
      </div>
    </div>
    <!-- 第三模块 -->
    <div class="three">
      <List :list="zujianlist" fuwu="fuwu"></List>
    </div>
    <div style="width: 100%; height: 60px; background-color: #ffffff"></div>
    <!-- 第四模块 -->
    <div class="four">
      <img src="../assets/home/beijing2.png" alt="" style="width: 100%" />
      <img
        src="../assets/home/jingxuan.png"
        style="position: absolute; top: 81px; left: 50%; margin-left: -187px"
      />
      <div class="jingxuanList">
        <div class="jingxuanItem">
          <img src="../assets/home/chanpin1.png" class="jingxuanItemPic" />
          <div class="jingxuanItemTitle">
            <div class="jingxuanItemTitle2">财付行小程序</div>
          </div>
        </div>
        <div class="jingxuanItem">
          <img src="../assets/home/chanpin2.png" class="jingxuanItemPic" />
          <div class="jingxuanItemTitle">
            <div class="jingxuanItemTitle2">中国福利彩票</div>
          </div>
        </div>
        <div class="jingxuanItem">
          <img src="../assets/home/chanpin3.png" class="jingxuanItemPic" />
          <div class="jingxuanItemTitle">
            <div class="jingxuanItemTitle2">中国电信加油优惠券</div>
          </div>
        </div>
        <div class="jingxuanItem">
          <img src="../assets/home/chanpin4.png" class="jingxuanItemPic" />
          <div class="jingxuanItemTitle">
            <div class="jingxuanItemTitle2">招商银行签到有礼</div>
          </div>
        </div>
      </div>
      <img
        src="../assets/home/chakangengduo.png"
        style="display: block; margin: 60px auto 0 auto"
      />
    </div>
    <!-- 第五模块 -->
    <div class="five" style="margin-top: 80px">
      <div style="height: 1px;"></div>
      <img
        src="../assets/home/youshi.png"
        style="margin: 100px auto 0 auto; display: block;"
      />
      <!-- <img
        src="../assets/home/tedian.png"
        style="position: absolute; top: 260px; left: 50%; margin-left: -600px;"
      /> -->
      
      <div class="ys-bg">
        <div
          class="tedianText"
          style="position: absolute; top: 80px; left: 0;"
        >
          1、为银行、交易所、基金、信托、私募等机构提供整体的解决方案和服务;<br />
          2、第三方支付设计与开发;<br />
          3、日间结算、登记清算系统的开发与应用;<br />
          4、单边、多边、跨境结算支付的创新与应用。
        </div>

        <div
          class="tedianText"
          style="position: absolute; top: 80px; right: 0"
        >
          1、容灾备份及信息系统高可用产品;<br />
          2、数据同步、数据零丢失、数据库容灾演练、数据库热备份、多种恢复机制、传输和存储加密、自定义备份策略管理。
        </div>

        <div
          class="tedianText"
          style="position: absolute; top: 355px; left: 0"
        >
          1、客户权益平台;<br />
          2、积分清算平台;<br />
          3、客户忠诚度计划搭建。
        </div>

        <div
          class="tedianText"
          style="position: absolute; top: 355px; right: 0"
        >
          1、完整的大数据行业统计流程方案、核心算法、平台;<br />
          2、数据收集、数据存储、数据计算、数据分析、优化业务、战略规划;<br />
          3、中国电子科学研究院、 国家大数据实验室 、新疆自治区政府 、新疆特侦、
          西安交通大学。
        </div>
        
      </div>
      
    </div>
    <!-- 第六模块 -->
    <div class="six">
      <div style="height: 1px;"></div>
      <img
        src="../assets/home/huoban.png"
        style="display: block; margin: 100px auto 0 auto;"
      />
      <div class="huobanList">
        <div class="huobanItem">
          <img
            src="../assets/home/logo1.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo2.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo3.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo4.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo5.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo6.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo7.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo8.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo9.png"
            style="width: 220px; height: 130px"
          />
        </div>
        <div class="huobanItem">
          <img
            src="../assets/home/logo10.png"
            style="width: 220px; height: 130px"
          />
        </div>
      </div>
      <!-- <img
        src="../assets/home/zuo.png"
        style="position: absolute; top: 366px; left: 254px"
      />
      <img
        src="../assets/home/you.png"
        style="position: absolute; top: 366px; right: 254px"
      /> -->
    </div>
  </div>
</template>

<script>
import List from "@/components/List.vue";
export default {
  components: {
    List,
  },
  data() {
    return {
      isFour: true,
      zujianlist: [
        {
          pic: "http://files.sxcfx.cn/upload/20220303/78f7497b615743c5b3747470b6be2fba.png",
          title: "金融",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/beea3ef2677d6f238964efe8ba9ca27c.png",
          title: "教育",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/2921760c57107a0e7cb82d3dcd4f7a5f.png",
          title: "烟草",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/8ffb9d922f25029b7f988986dfccecda.png",
          title: "商品交易",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/082ad2bc06d6cfff870d29c07151c685.png",
          title: "零售",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/d9305416bfdc3be59579121465f00e13.png",
          title: "政府",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/d39970cdf77f2ff9caa98620d06e78fe.png",
          title: "高科技",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/b558e278ce26b0dac7e79e1322d6dba2.png",
          title: "能源",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/178642ca8a2abf4de758a1f198980be7.png",
          title: "生产制造",
        },
        {
          pic: "http://files.sxcfx.cn/upload/20220304/289e66f22cf439f402a067c994783fa6.png",
          title: "园区",
        },
      ],
      resolveList: [
        {
          title: "系统化综合解决方案",
          extends: "为业主方提供底层及前端平台软 件开发服务及互联网运营服务",
        },
        {
          title: "异业合作能力赋能",
          extends: "提供出行行业与第三方平台 异业合作及资源共享机会",
        },
        {
          title: "创新模块开发",
          extends: "可为业主方提供创新的 项目运营增值服务模块",
        },
        {
          title: "数据流量变现",
          extends:
            "精准画像级别广告分发及投放，建立客户数字权益平台，实现用户价值最大化",
        },
        {
          title: "营销资源导入",
          extends: "金融，能源行业营销资源导入",
        },
      ],
    };
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}
.page {
  position: relative;
  width: 100%;
  background-color: #fff;
}
.one {
  height: 594px;
}
.one img {
  position: absolute;
  top: -79px;
  left: 0;
  width: 433px;
  height: 573px;
  z-index: 888;
}
.one .more {
  margin-left: 1010px;
  margin-top: 495px;
  width: 150px;
  height: 48px;
  z-index: 999;
}
.content {
  position: absolute;
  top: 99px;
  right: 0;
  width: 691px;
  height: 240px;
}
.content_top {
  display: flex;
  align-items: center;
  margin-bottom: 46px;
}
.ct_one {
  height: 35px;
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 32px;
  margin-right: 10px;
}
.content_mid {
  width: 691px;
  height: 159px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 35px;
}
.two {
  position: relative;
  width: 100%;
  height: 624px;
  background: url("../assets/home/beijing1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
}
.two img {
  margin: 0 auto;
  width: 393px;
  height: 100px;
  display: block;
}
.two_content {
  width: 1168px;
  height: 257px;
  margin: 60px auto 0 auto;
  display: flex;
  justify-content: space-between;
}
.two_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 210px;
}
.item_logo1 {
  background: url("../assets/home/j1-1.png") no-repeat center 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a8111a;
}
.hover1 :hover {
  background-color: #a8111a !important;
  background: url("../assets/home/j1-2.png") no-repeat center 50%;
}
.hover2 :hover {
  background-color: #a8111a !important;
  background: url("../assets/home/j2-1.png") no-repeat center 50%;
}
.hover3 :hover {
  background-color: #a8111a !important;
  background: url("../assets/home/j3-2.png") no-repeat center 50%;
}
.hover4 :hover {
  background-color: #a8111a !important;
  background: url("../assets/home/j4-2.png") no-repeat center 50%;
}
.hover5 :hover {
  background-color: #a8111a !important;
  background: url("../assets/home/j5-2.png") no-repeat center 50%;
}
.item_logo2 {
  background: url("../assets/home/j2-2.png") no-repeat center 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a8111a;
}
.item_logo3 {
  background: url("../assets/home/j3-1.png") no-repeat center 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a8111a;
}
.item_logo4 {
  background: url("../assets/home/j4-1.png") no-repeat center 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a8111a;
}
.item_logo5 {
  background: url("../assets/home/j5-1.png") no-repeat center 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a8111a;
}
.item_title {
  height: 20px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.item_extend {
  height: 64px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #888888;
  line-height: 24px;
}
.four {
  position: relative;
  width: 100%;
  height: 780px;
}
.jingxuanList {
  position: relative;
  width: 1200px;
  height: 380px;
  /* top: 265px;
  left: 360px; */
  margin: -80px auto 0 auto;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jingxuanItem {
  height: 380px;
  width: 285.5px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}
.jingxuanItemPic {
  width: 265.5px;
  height: 286px;
}
.jingxuanItemTitle2 {
  text-align: center;
  height: 74px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 74px;
}
.jingxuanItemTitle :hover {
  color: #a8111a;
}
.five {
  width: 100%;
  height: 862px;
  position: relative;
  background: url("../assets/home/beijing3.png") no-repeat 100% 100%;
}
.ys-bg {
  width: 1199px; min-height: 446px; margin: 0 auto; background: url('../assets/home/tedian.png') no-repeat; background-size: 100% 100%;
  margin-top: 100px;
  position: relative;
}

.tedianText {
  width: 300px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}
.six {
  position: relative;
  width: 100%;
  height: 645px;
  background: url("../assets/home/beijing7.png") no-repeat 100% 100%;
}
.huobanList {
  /* position: absolute;
  top: 260px;
  left: 360px; */
  margin: 0 auto;
  height: 284px;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
</style>