<template>
  <div id="app">
    <div class="nav"
    :style="{
      height:(Number(screenWidth) * 135) / 1920 + 'px',
    }">
      <div class="top"></div>
      <img src="./assets/home/logo.png" class="sxwl" :style="{
       height: (Number(screenWidth) * 76) / 1920 + 'px',
       left:(Number(screenWidth) * 360) / 1920 + 'px',
       top:(Number(screenWidth) * 32) / 1920 + 'px',
      }" />
      <img src="./assets/home/dingbu.png" class="top_img" :style="{
       width: (Number(screenWidth) * 1374) / 1920 + 'px',
      }"  />
      <div class="top_title" :style="{
        width: (Number(screenWidth) * 1374) / 1920 + 'px',
        height: (Number(screenWidth) * 45) / 1920 + 'px',
        marginRight: (Number(screenWidth) * 360) / 1920 + 'px',
        fontSize: (Number(screenWidth) * 16) / 1920 + 'px',
      }">
        <span class="top_title_firshChild"
        :style="{
        marginRight: (Number(screenWidth) * 365) / 1920 + 'px',
      }"
        >欢迎来到盛行网络科技有限公司！</span>
        <div class="login_register"
        :style="{
        width: (Number(screenWidth) * 84) / 1920 + 'px',
        marginRight: (Number(screenWidth) * 40) / 1920 + 'px',
        fontSize: (Number(screenWidth) * 14) / 1920 + 'px',
      }"
        ><span>登录</span>|<span>注册</span></div>
        <img src="./assets/home/dianhua.png" class="phone_img"   :style="{
        width: (Number(screenWidth) * 16) / 1920 + 'px',
        height: (Number(screenWidth) * 22) / 1920 + 'px',
      }"/>
        <span>联系电话：029-84505016</span>
      </div>
      <div class="nav4" :style="{
        width: (Number(screenWidth) * 550) / 1920 + 'px',
        height: (Number(screenWidth) * 90) / 1920 + 'px',
        top: (Number(screenWidth) * 45) / 1920 + 'px',
        marginRight: (Number(screenWidth) * 370) / 1920 + 'px',
      }">
        <router-link
          v-for="(item, index) in NavList"
          :key="index"
          :to="item.link"
          @click.native="navClick(index)"
          :class="{ nav4Active: chooseIndex === index }"
        >
          {{ item.name }}
        </router-link>
      </div>
    </div>
    <el-carousel :height="(Number(screenWidth) * 700) / 1920 + 'px'">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img
          :src="item"
          :style="{
            width: screenWidth + 'px',
            height: (Number(screenWidth) * 700) / 1920 + 'px',
          }"
        />
      </el-carousel-item>
    </el-carousel>

    <router-view />
    <div class="footer">
      <div style="width: 1200px; margin: 0 auto; position: relative;">
        <div class="footer_content">
          <div class="footer_one">西安盛行网络科技有限公司</div>
          <div class="footer_two">金融服务 ｜ 信息安全 ｜ 行业领域 ｜ 大数据</div>
          <div
            style="
              width: 337px;
              height: 2px;
              background: #ffffff;
              margin: 20px 0 25px 0;
            "
          ></div>
          <div class="footer_info">
            <img
              src="./assets/home/lianxiren.png"
              style="width: 24px; height: 28px; margin-right: 24px"
            />
            <span>韩先生</span>
          </div>
          <div class="footer_info">
            <img
              src="./assets/home/dianhua1.png"
              style="width: 28px; height: 28px; margin-right: 24px"
            />
            <span>029-84505016</span>
          </div>
          <div class="footer_info">
            <img
              src="./assets/home/youxiang.png"
              style="width: 28px; height: 21px; margin-right: 24px"
            />
            <span>hanzhe@goboosoft.com</span>
          </div>
          <div class="footer_info">
            <img
              src="./assets/home/address.png"
              style="width: 24px; height: 32px; margin-right: 24px"
            />
            <span>陕西省西安市雁塔区绿地领海大厦B座（南门）2006</span>
          </div>
        </div>
        <img
          src="./assets/home/erweima.png"
          style="
            width: 190px;
            height: 190px;
            position: absolute;
            top: 95px;
            right: 0;
          "
        />
        <div class="sxgzh">盛行公众号</div>
      </div>
      <div class="footer_bottom">
        西安盛行网络科技有限公司 Copyright © 2024 All Rights Reserved
        <a href="https://beian.miit.gov.cn" target="_blank" style="color: #fff; text-decoration: none;">陕ICP备2021013194号-3</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      bannerList: [
        "http://files.sxcfx.cn/upload/20220303/d41ed773af6b0d6a8c975e777d85ea87.png",
        "http://files.sxcfx.cn/upload/20220303/d41ed773af6b0d6a8c975e777d85ea87.png",
        "http://files.sxcfx.cn/upload/20220303/d41ed773af6b0d6a8c975e777d85ea87.png",
      ],
      chooseIndex: 0,
      NavList: [
        { name: "首页", link: "/" },
        { name: "关于我们", link: "/about" },
        { name: "产品中心", link: "/product" },
        { name: "联系我们", link: "/contact" },
      ],
    };
  },
  methods: {
    navClick(index) {
      this.chooseIndex = index;
    },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
      })();
    };
  },
   watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.screenWidth);
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
}
.nav {
  width: 100%;
  height: 135px;
  background-color: #ffffff;
  position: relative;
}
.sxwl {
  height: 76px;
  position: absolute;
  left: 360px;
  top: 32px;
}
.top_title {
  position: absolute;
  right: 0;
  top: 0;
  width: 1374px;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  z-index: 200;
  align-items: center;
  margin-right: 360px;
  color: #fceaa6;
  font-size: 16px;
}
.top_title_firshChild {
  margin-right: 365px;
}
.login_register {
  width: 84px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-right: 40px;
}
.top_title :nth-child(3) {
  margin-right: 9px;
}
.top_img {
  width: 1374px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
.phone_img {
  width: 16px;
  height: 22px;
  display: block;
}
.banner_image {
  width: 100%;
  height: 700px;
}
.nav4 {
  width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  position: absolute;
  right: 0;
  top: 45px;
  margin-right: 370px;
}
.nav4 :first-child,
.nav4 :nth-child(2),
.nav4 :nth-child(3),
.nav4 :nth-child(4) {
  width: 20%;
  height:10%;
  line-height: 10%;
  text-decoration: none;
  text-align: center;
  color: #333333;
  font-size: 20px;
  font-weight: 400;
}
.nav4 :first-child:hover,
.nav4 :nth-child(2):hover,
.nav4 :nth-child(3):hover,
.nav4 :nth-child(4):hover {
  text-decoration: none;
  color: #9b2a26;
  background-image: url("./assets/home/xuanzhong.png");
  background-repeat: no-repeat;
}
.nav4Active {
  text-decoration: none;
  color: #9b2a26 !important;
  background-image: url("./assets/home/xuanzhong.png");
  background-repeat: no-repeat;
}
.footer {
  background-color: #292c32;
  position: relative;
  width: 100%;
  height: 460px;
}
.footer_content {
  position: absolute;
  top: 57px;
  left: 0;
  width: 523px;
  height: 309px;
}
.footer_one {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 29px;
}
.footer_two {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.footer_info {
  display: flex;
  align-items: center;
}
.footer_info span {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 40px;
}
.footer_bottom {
  width: 100%;
  height: 56px;
  background: #22252b;
  line-height: 56px;
  position: absolute;
  top: 404px;
  text-align: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.sxgzh {
  position: absolute;
  right: 40px;
  top: 314px;
  height: 19px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
}
</style>
