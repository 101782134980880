<template>
  <div class="page">
    <img class="title_pic" src="../assets/home/fuwu.png" v-if="fuwu" />
    <img class="title_pic1" src="../assets/chanpin.png" v-if="chanpin" />
    <img class="title_pic1" src="../assets/anli.png" v-if="anli" />
    <span v-if="fuwu"
      >现在, 已有超过23个行业的企业或组织正在享受盛行软件所提供的各种服务…</span
    >
    <div class="list">
      <div
        class="item"
        :class="{ item1: isFour }"
        v-for="(item, index) in list"
        :key="index"
      >
        <div>
          <img
            :src="item.pic"
            class="item_img"
            :class="{ item_img1: isFour }"
          />
        </div>
        <div class="item_title">
          <div class="item_title_title" :class="{ item_title_title1: isFour }">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <img
      src="../assets/home/chakangengduo.png"
      style="width: 150px; height: 48px; margin: 20px auto; display: block"
      v-if="!fuwu"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    isFour: {
      type: Boolean,
      default: false,
    },
    fuwu: {
      type: String,
      default: "",
    },
    anli: {
      type: String,
      default: "",
    },
    chanpin: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.page {
  width: 100%;
  position: relative;
}
.title_pic1 {
  margin: 100px 764px 0 764px;
  width: 419px;
  height: 100px;
  margin-bottom: 41px;
}
.title_pic {
  margin: 100px auto 0 auto;
  width: 373px;
  height: 100px;
  margin-bottom: 41px;
  display: block;
}
.page span {
  display: block;
  margin: 0 auto;
  width: 689px;
  height: 22px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  margin-bottom: 60px;
}
.list {
  /* margin-left: 360px; */
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  width: 224px;
  height: 250px;
  text-align: center;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}
.item_img {
  display: block;
  width: 224px;
  height: 200px;
  margin: 0;
}
.item_title_title {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.item1 {
  width: 285px;
  height: 300px;
  text-align: center;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}
.item_img1 {
  display: block;
  width: 285px;
  height: 240px;
  margin: 0;
}
.item_title_title1 {
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}

.item_title :hover {
  background: #9b2a26;
  color: #ffffff;
}
</style>