var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"nav",style:({
    height:(Number(_vm.screenWidth) * 135) / 1920 + 'px',
  })},[_c('div',{staticClass:"top"}),_c('img',{staticClass:"sxwl",style:({
     height: (Number(_vm.screenWidth) * 76) / 1920 + 'px',
     left:(Number(_vm.screenWidth) * 360) / 1920 + 'px',
     top:(Number(_vm.screenWidth) * 32) / 1920 + 'px',
    }),attrs:{"src":require("./assets/home/logo.png")}}),_c('img',{staticClass:"top_img",style:({
     width: (Number(_vm.screenWidth) * 1374) / 1920 + 'px',
    }),attrs:{"src":require("./assets/home/dingbu.png")}}),_c('div',{staticClass:"top_title",style:({
      width: (Number(_vm.screenWidth) * 1374) / 1920 + 'px',
      height: (Number(_vm.screenWidth) * 45) / 1920 + 'px',
      marginRight: (Number(_vm.screenWidth) * 360) / 1920 + 'px',
      fontSize: (Number(_vm.screenWidth) * 16) / 1920 + 'px',
    })},[_c('span',{staticClass:"top_title_firshChild",style:({
      marginRight: (Number(_vm.screenWidth) * 365) / 1920 + 'px',
    })},[_vm._v("欢迎来到盛行网络科技有限公司！")]),_c('div',{staticClass:"login_register",style:({
      width: (Number(_vm.screenWidth) * 84) / 1920 + 'px',
      marginRight: (Number(_vm.screenWidth) * 40) / 1920 + 'px',
      fontSize: (Number(_vm.screenWidth) * 14) / 1920 + 'px',
    })},[_c('span',[_vm._v("登录")]),_vm._v("|"),_c('span',[_vm._v("注册")])]),_c('img',{staticClass:"phone_img",style:({
      width: (Number(_vm.screenWidth) * 16) / 1920 + 'px',
      height: (Number(_vm.screenWidth) * 22) / 1920 + 'px',
    }),attrs:{"src":require("./assets/home/dianhua.png")}}),_c('span',[_vm._v("联系电话：029-84505016")])]),_c('div',{staticClass:"nav4",style:({
      width: (Number(_vm.screenWidth) * 550) / 1920 + 'px',
      height: (Number(_vm.screenWidth) * 90) / 1920 + 'px',
      top: (Number(_vm.screenWidth) * 45) / 1920 + 'px',
      marginRight: (Number(_vm.screenWidth) * 370) / 1920 + 'px',
    })},_vm._l((_vm.NavList),function(item,index){return _c('router-link',{key:index,class:{ nav4Active: _vm.chooseIndex === index },attrs:{"to":item.link},nativeOn:{"click":function($event){return _vm.navClick(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]),_c('el-carousel',{attrs:{"height":(Number(_vm.screenWidth) * 700) / 1920 + 'px'}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{style:({
          width: _vm.screenWidth + 'px',
          height: (Number(_vm.screenWidth) * 700) / 1920 + 'px',
        }),attrs:{"src":item}})])}),1),_c('router-view'),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"width":"1200px","margin":"0 auto","position":"relative"}},[_c('div',{staticClass:"footer_content"},[_c('div',{staticClass:"footer_one"},[_vm._v("西安盛行网络科技有限公司")]),_c('div',{staticClass:"footer_two"},[_vm._v("金融服务 ｜ 信息安全 ｜ 行业领域 ｜ 大数据")]),_c('div',{staticStyle:{"width":"337px","height":"2px","background":"#ffffff","margin":"20px 0 25px 0"}}),_c('div',{staticClass:"footer_info"},[_c('img',{staticStyle:{"width":"24px","height":"28px","margin-right":"24px"},attrs:{"src":require("./assets/home/lianxiren.png")}}),_c('span',[_vm._v("韩先生")])]),_c('div',{staticClass:"footer_info"},[_c('img',{staticStyle:{"width":"28px","height":"28px","margin-right":"24px"},attrs:{"src":require("./assets/home/dianhua1.png")}}),_c('span',[_vm._v("029-84505016")])]),_c('div',{staticClass:"footer_info"},[_c('img',{staticStyle:{"width":"28px","height":"21px","margin-right":"24px"},attrs:{"src":require("./assets/home/youxiang.png")}}),_c('span',[_vm._v("hanzhe@goboosoft.com")])]),_c('div',{staticClass:"footer_info"},[_c('img',{staticStyle:{"width":"24px","height":"32px","margin-right":"24px"},attrs:{"src":require("./assets/home/address.png")}}),_c('span',[_vm._v("陕西省西安市雁塔区绿地领海大厦B座（南门）2006")])])]),_c('img',{staticStyle:{"width":"190px","height":"190px","position":"absolute","top":"95px","right":"0"},attrs:{"src":require("./assets/home/erweima.png")}}),_c('div',{staticClass:"sxgzh"},[_vm._v("盛行公众号")])]),_c('div',{staticClass:"footer_bottom"},[_vm._v(" 西安盛行网络科技有限公司 Copyright © 2024 All Rights Reserved "),_c('a',{staticStyle:{"color":"#fff","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("陕ICP备2021013194号-3")])])])}]

export { render, staticRenderFns }